import {
  MenuFoldOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  DashboardFilled,
  UserAddOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  LogoutOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { FaUserShield, FaUserPlus, FaHandshakeSlash } from "react-icons/fa";
import { FaUserXmark, FaHandshake, FaIndustry } from "react-icons/fa6";
import { MdAddTask, MdAssignmentAdd } from "react-icons/md";
import { PiListBold } from "react-icons/pi";
import { RiPlayList2Line, RiDonutChartFill } from "react-icons/ri";
import { CiCreditCardOff } from "react-icons/ci";
import { GrDocumentTime } from "react-icons/gr";
import { Button, Layout, Menu, theme, Avatar, Space, Dropdown } from "antd";
import { useState, useEffect } from "react";
import { Outlet, NavLink, Link, useLocation } from "react-router-dom";
import LegalLogo from "../../assets/images/logo.jpeg";
import { logout } from "../../components/messages/ResponseMessages";
import { MdSupportAgent } from "react-icons/md";
import { ImUserTie } from "react-icons/im";
import { GrUserSettings } from "react-icons/gr";
import { VscGraph } from "react-icons/vsc";
import { LuStamp } from "react-icons/lu";
import { FaFileSignature } from "react-icons/fa";
import ContractIcon from "../../assets/images/contracts.png";
import { GiNotebook } from "react-icons/gi";
import { BsFileEarmarkRuled } from "react-icons/bs";

const role = localStorage.getItem("user_role");
const userRole = role && role;
const userName = localStorage.getItem("user_name");
const userEmail = localStorage.getItem("user_email");
const { SubMenu } = Menu;

const { Header, Sider, Content, Footer } = Layout;
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const handleLogout = () => {
  logout("Logged-out Successfully.");
  localStorage.clear();
};

const superAdminMenu = [
  getItem(
    <Link to="dashboard">Dashboard</Link>,
    "/admin/dashboard",
    <DashboardFilled />
  ),
  getItem(
    <Link to="contracts">Contracts</Link>,
    "/admin/contracts",

    <GiNotebook />
  ),
  getItem(
    <Link to="analytics">Analytics</Link>,
    "/admin/analytics",
    <VscGraph />
  ),
  // getItem("User", "sub3", <UserOutlined />, [
  //   getItem(
  //     <Link to="create_user">Add User</Link>,
  //     "/admin/create_user",
  //     <FaUserPlus />
  //   ),
  //   getItem(
  //     <Link to="active_users">Active Users</Link>,
  //     "/admin/active_users",
  //     <FaUserShield />
  //   ),
  //   getItem(
  //     <Link to="inactive_users">Inactive Users</Link>,
  //     "/admin/inactive_users",
  //     <FaUserXmark />
  //   ),
  // ]),

  // getItem("Company", "sub1", <FaIndustry />, [
  //   getItem(
  //     <Link to="create_company">Add Company</Link>,
  //     "/admin/create_company"
  //   ),
  //   getItem(
  //     <Link to="active_companies">Active Companies</Link>,
  //     "/admin/active_companies"
  //   ),
  //   getItem(
  //     <Link to="inactive_companies">Inctive Companies</Link>,
  //     "/admin/inactive_companies"
  //   ),
  // ]),

  // getItem("Agreement", "sub2", <FaHandshake />, [
  //   getItem(
  //     <Link to="create_request">Raise New Request</Link>,
  //     "/admin/create_request",
  //     <MdAssignmentAdd />
  //   ),
  //   getItem(
  //     <Link to="fresh_request">Fresh Request</Link>,
  //     "/admin/fresh_request",
  //     <PiListBold />
  //   ),
  //   getItem(
  //     <Link to="under_negotiation">Under Negotiation</Link>,
  //     "/admin/under_negotiation",
  //     <MdAssignmentAdd />
  //   ),
  //   getItem(
  //     <Link to="under_execution">Under Execution</Link>,
  //     "/admin/under_execution",
  //     <RiPlayList2Line />
  //   ),
  //   getItem(
  //     <Link to="executed">Executed</Link>,
  //     "/admin/executed",
  //     <MdAddTask />
  //   ),
  //   getItem(
  //     <Link to="transaction_closed">Transaction Closed</Link>,
  //     "/admin/transaction_closed",
  //     <CiCreditCardOff />
  //   ),
  //   // getItem(
  //   //   <Link to="under_negotiation">Upcoming Renewals</Link>,
  //   //   "/admin/transaction_closed",
  //   //   <GrDocumentTime />
  //   // ),
  //   // getItem(
  //   //   <Link to="under_negotiation">Agreement Expired</Link>,
  //   //   "/admin/under_negotiation",
  //   //   <FaHandshakeSlash />
  //   // ),
  // ]),
  getItem(
    <Link to="e-sign">E-sign & E-stamp</Link>,
    "/admin/e-sign",
    <FaFileSignature />
  ),
  // getItem(<Link to="e-stamp">E-stamp</Link>, "/admin/e-stamp", <LuStamp />),

  getItem(
    <Link to="template">Templates</Link>,
    "/admin/template",
    <FileTextOutlined />
  ),
  getItem(
    <Link to="clause">Clause</Link>,
    "/admin/master-template",
    <BsFileEarmarkRuled />
  ),
  getItem(
    <Link to="settings">Settings</Link>,
    "/admin/settings",
    <SettingOutlined />
  ),
];

const adminMenu = [
  getItem(
    <Link to="dashboard">Dashboard</Link>,
    "/admin/dashboard",
    <DashboardFilled />
  ),
  getItem(
    <Link to="contracts">Contracts</Link>,
    "/admin/contracts",

    <GiNotebook />
  ),
  getItem(
    <Link to="analytics">Analytics</Link>,
    "/admin/analytics",
    <VscGraph />
  ),
  // getItem(
  //   <Link to="create_user">Add User</Link>,
  //   "/admin/create_user",
  //   <FaUserPlus />
  // ),
  // getItem(
  //   <Link to="active_users">Active Users</Link>,
  //   "/admin/active_users",
  //   <FaUserShield />
  // ),
  // getItem(
  //   <Link to="inactive_users">Inactive Users</Link>,
  //   "/admin/inactive_users",
  //   <FaUserXmark />
  // ),
  // getItem("Agreement", "sub1", <FaHandshake />, [
  //   getItem(
  //     <Link to="create_request">Raise New Request</Link>,
  //     "/admin/create_request",
  //     <MdAssignmentAdd />
  //   ),
  //   getItem(
  //     <Link to="fresh_request">Fresh Request</Link>,
  //     "/admin/fresh_request",
  //     <PiListBold />
  //   ),
  //   getItem(
  //     <Link to="under_negotiation">Under Negotiation</Link>,
  //     "/admin/under_negotiation",
  //     <MdAssignmentAdd />
  //   ),
  //   getItem(
  //     <Link to="under_execution">Under Execution</Link>,
  //     "/admin/under_execution",
  //     <RiPlayList2Line />
  //   ),
  //   getItem(
  //     <Link to="executed">Executed</Link>,
  //     "/admin/executed",
  //     <MdAddTask />
  //   ),
  //   getItem(
  //     <Link to="transaction_closed">Transaction Closed</Link>,
  //     "/admin/transaction_closed",
  //     <CiCreditCardOff />
  //   ),
  //   // getItem(
  //   //   <Link to="under_negotiation">Upcoming Renewals</Link>,
  //   //   "/admin/transaction_closed",
  //   //   <GrDocumentTime />
  //   // ),
  //   // getItem(
  //   //   <Link to="under_negotiation">Agreement Expired</Link>,
  //   //   "/admin/under_negotiation",
  //   //   <FaHandshakeSlash />
  //   // ),
  // ]),
  getItem(
    <Link to="e-sign">E-sign</Link>,
    "/admin/e-sign",
    <FaFileSignature />
  ),
  getItem(<Link to="e-stamp">E-stamp</Link>, "/admin/e-stamp", <LuStamp />),
  getItem(
    <Link to="settings">Settings</Link>,
    "/admin/settings",
    <SettingOutlined />
  ),
];

const othersMenu = [
  getItem(
    <Link to="dashboard">Dashboard</Link>,
    "/admin/dashboard",
    <DashboardFilled />
  ),
  getItem(
    <Link to="contracts">Contracts</Link>,
    "/admin/contracts",
    <GiNotebook />
  ),
  // getItem(
  //   <Link to="create_request">Raise New Request</Link>,
  //   "/admin/create_request",
  //   <MdAssignmentAdd />
  // ),
  // getItem(
  //   <Link to="fresh_request">Fresh Request</Link>,
  //   "/admin/fresh_request",
  //   <PiListBold />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Under Negotiation</Link>,
  //   "/admin/under_negotiation",
  //   <MdAssignmentAdd />
  // ),
  // getItem(
  //   <Link to="under_execution">Under Execution</Link>,
  //   "/admin/under_execution",
  //   <RiPlayList2Line />
  // ),
  // getItem(
  //   <Link to="executed">Executed</Link>,
  //   "/admin/executed",
  //   <MdAddTask />
  // ),
  // getItem(
  //   <Link to="transaction_closed">Transaction Closed</Link>,
  //   "/admin/transaction_closed",
  //   <CiCreditCardOff />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Upcoming Renewals</Link>,
  //   "/admin/transaction_closed",
  //   <GrDocumentTime />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Agreement Expired</Link>,
  //   "/admin/under_negotiation",
  //   <FaHandshakeSlash />
  // ),
  getItem(
    <Link to="analytics">Analytics</Link>,
    "/admin/analytics",
    <VscGraph />
  ),
  getItem(
    <Link to="e-sign">E-sign</Link>,
    "/admin/e-sign",
    <FaFileSignature />
  ),
  getItem(<Link to="e-stamp">E-stamp</Link>, "/admin/e-stamp", <LuStamp />),
  getItem(
    <Link to="settings">Settings</Link>,
    "/admin/settings",
    <SettingOutlined />
  ),
];
const legalMenu = [
  getItem(
    <Link to="dashboard">Dashboard</Link>,
    "/admin/dashboard",
    <DashboardFilled />
  ),
  getItem(
    <Link to="contracts">Contracts</Link>,
    "/admin/contracts",
    <GiNotebook />
  ),
  // getItem(
  //   <Link to="create_request">Raise New Request</Link>,
  //   "/admin/create_request",
  //   <MdAssignmentAdd />
  // ),
  // getItem(
  //   <Link to="fresh_request">Fresh Request</Link>,
  //   "/admin/fresh_request",
  //   <PiListBold />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Under Negotiation</Link>,
  //   "/admin/under_negotiation",
  //   <MdAssignmentAdd />
  // ),
  // getItem(
  //   <Link to="under_execution">Under Execution</Link>,
  //   "/admin/under_execution",
  //   <RiPlayList2Line />
  // ),
  // getItem(
  //   <Link to="executed">Executed</Link>,
  //   "/admin/executed",
  //   <MdAddTask />
  // ),
  // getItem(
  //   <Link to="transaction_closed">Transaction Closed</Link>,
  //   "/admin/transaction_closed",
  //   <CiCreditCardOff />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Upcoming Renewals</Link>,
  //   "/admin/transaction_closed",
  //   <GrDocumentTime />
  // ),
  // getItem(
  //   <Link to="under_negotiation">Agreement Expired</Link>,
  //   "/admin/under_negotiation",
  //   <FaHandshakeSlash />
  // ),
  getItem(
    <Link to="analytics">Analytics</Link>,
    "/admin/analytics",
    <VscGraph />
  ),
  getItem(
    <Link to="e-sign">E-sign</Link>,
    "/admin/e-sign",
    <FaFileSignature />
  ),
  getItem(<Link to="e-stamp">E-stamp</Link>, "/admin/e-stamp", <LuStamp />),

  getItem(
    <Link to="template">Templates</Link>,
    "/admin/template",
    <FileTextOutlined />
  ),
  getItem(
    <Link to="clause">Clause</Link>,
    "/admin/master-template",
    <BsFileEarmarkRuled />
  ),
  getItem(
    <Link to="settings">Settings</Link>,
    "/admin/settings",
    <SettingOutlined />
  ),
  // role === "legalhead" || role === "legalteam" || role === "superadmin" ? getItem(
  //   <Link to="clause">Clause</Link>,
  //   "/admin/master-template",
  //   <BsFileEarmarkRuled />
  // ) : null,
];
const items = [
  {
    label: (
      <div className="cursor-auto">
        <span className="font-bold">Signed in as</span> <br />
        <h1>{userEmail && userEmail}</h1>
      </div>
    ),
    key: "0",
  },
  {
    type: "divider",
  },
  {
    label: (
      <div className="cursor-auto">
        <h3 className="flex items-center cursor-auto">
          <ImUserTie className="mr-2" /> Role : {role && role}
        </h3>
      </div>
    ),
    key: "1",
  },
  {
    type: "divider",
  },
  // {
  //   label: (
  //     <h3 className="flex items-center">
  //       <GrUserSettings className="mr-2" /> Account Settings
  //     </h3>
  //   ),
  //   key: "2",
  // },
  {
    label: (
      <h3 className="flex items-center">
        <MdSupportAgent className="mr-2" /> Support
      </h3>
    ),
    key: "3",
  },

  {
    type: "divider",
  },
  {
    label: (
      <Link
        onClick={() => handleLogout()}
        className="flex items-center"
        to={"/auth/login"}
      >
        <LogoutOutlined className="mr-2" /> <span>Signout</span>
      </Link>
    ),
    key: "4",
  },
];

const AntSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  // const isDashboardPage =
  //   location.pathname.startsWith("/admin/dashboard") ||
  //   location.pathname.startsWith("/admin/analytics");
  const isDashboardPage = /^\/admin\/(dashboard)(?:\/|$)/.test(
    location.pathname
  );
  const isAnalyticsPage = /^\/admin\/(analytics)(?:\/|$)/.test(
    location.pathname
  );

  // Function to determine the selected key based on the current path
  const getSelectedKey = () => {
    for (const item of adminMenu) {
      if (item.subMenu) {
        for (const subItem of item.subMenu) {
          if (subItem.path === currentPath) {
            return subItem.key;
          }
        }
      } else if (item.path === currentPath) {
        return item.key;
      }
    }
    return ""; // Set a default key if no match is found
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let componentToRender;
  if (userRole && userRole.toLowerCase() === "admin") {
    componentToRender = (
      <Menu
        style={{ background: "#2A2C38", color: "#CCEBFF" }}
        mode="inline"
        items={adminMenu}
        defaultSelectedKeys={selectedKey}
        className="mt-4"
      ></Menu>
    );
  } else if (userRole && userRole.toLowerCase() === "superadmin") {
    componentToRender = (
      <Menu
        style={{ background: "#2A2C38", color: "#CCEBFF" }}
        mode="inline"
        items={superAdminMenu}
        defaultSelectedKeys={[location.pathname]}
        className="mt-4"
      ></Menu>
    );
  } else if (
    userRole &&
    (userRole.toLowerCase() === "legalhead" ||
      userRole.toLowerCase() === "legalteam")
  ) {
    componentToRender = (
      <Menu
        style={{ background: "#2A2C38", color: "#CCEBFF" }}
        mode="inline"
        items={legalMenu}
        defaultSelectedKeys={[location.pathname]}
        className="mt-4"
      ></Menu>
    );
  } else {
    componentToRender = (
      <Menu
        style={{ background: "#2A2C38", color: "#CCEBFF" }}
        mode="inline"
        items={othersMenu}
        defaultSelectedKeys={[location.pathname]}
        className="mt-4"
      ></Menu>
    );
  }

  // Function to get initials from a name
  const getInitials = (name) => {
    if (!name) {
      // Redirect user to admin/login if name is not available
      window.location.href = "/auth/login"; // Update the URL as needed
      return null; // Return null or handle the redirection in the desired way
    }
    const parts = name && name.split(" ");
    return parts.map((part) => part[0]).join("");
  };

  // Calculate initials from the name
  const initials = getInitials(localStorage.getItem("user_name"));

  // Style for the purple rounded background
  const rounderStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#A395DA", // Purple color
    color: "#fff", // White text color
    padding: "0.5rem", // Adjust padding as needed
    width: "3rem", // Set a fixed width
    height: "3rem", // Set a fixed height
    maxWidth: "none", // Allow the box to expand fully
    maxHeight: "none", // Allow the box to expand fully
  };

  return (
    <Layout className="flex bg-violet-100">
      <Header
        style={{
          padding: 0,
          // background: colorBgContainer,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
        className="fixed-header bg-violet-100"
      >
        <div
          style={{
            padding: 0,
            // background: colorBgContainer,
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <div className="demo-logo-vertical">
            {/* <img
              src={LegalLogo}
              alt="Logo"
              className="legal-logo"
              style={{
                maxHeight: "100%",
                maxWidth: "150%",
                height: "auto",
                width: "auto",
                marginLeft: "1rem",
              }}
            />
          </div> */}
            <div
              style={{
                textAlign: "left",
                marginBottom: 20,
                fontSize: "40px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1 className="ml-4">
                <span className="text-indigo-600">C</span>ore
                <span className="text-indigo-600">V</span>entum
              </h1>
            </div>
          </div>

          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              // marginLeft: collapsed ? "" : "5em",
            }}
          />
        </div>

        <Space
          wrap
          size={16}
          style={{
            marginRight: "4em",
            marginLeft: "4em",
          }}
        >
          {/* <SearchOutlined /> */}
          <div className="flex items-center justify-between">
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {/* <Avatar
                    src="https://tecdn.b-cdn.net/img/new/standard/city/041.jpg"
                    size="large"
                  /> */}
                  <div style={rounderStyle}>{initials}</div>
                  <span className="text-balck text-lg ml-2">
                    {userName && userName}
                  </span>
                </Space>
              </a>
            </Dropdown>
          </div>
        </Space>
      </Header>
      <Layout
        style={{
          marginTop: "4rem",
          height: "94vh",
          backgroundColor: "#EBE8F7",
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            marginTop: "24px",
            borderRadius: "10px",
            position: "fixed",
            left: 0,
            height: "89vh",
            zIndex: 1000,
            backgroundColor: "#2A2C38",
          }}
          className="rounded"
        >
          <div className="demo-logo-vertical overflow-y-scroll">
            {/* <img
              src={LegalLogo}
              alt="Logo"
              className="legal-logo"
              style={{
                // borderRadius: "20px",
                borderTopRightRadius: "1rem",
              }}
            /> */}
          </div>
          {componentToRender}
        </Sider>

        <Content
          style={{
            marginTop: "24px",
            padding: isDashboardPage ? "0" : "24px",
            maxHeight: "calc(100vh - 105px)", // Adjust the maximum height as needed
            overflowY: "auto",
            background: isDashboardPage
              ? "white"
              : isAnalyticsPage
              ? ""
              : colorBgContainer,
            marginLeft: collapsed ? "7em" : "15.5em",
            transition: "margin-left 0.2s",
            borderTopLeftRadius: "1rem",
            marginRight: "1.5rem",
          }}
        >
          <div className="overflow">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AntSidebar;
